'use client';

import Image from 'next/image';
import Typography from '@mui/material/Typography';
import { useLocale, useTranslations } from 'next-intl';
import Button from '@mui/material/Button';
import { usePathname, useRouter } from 'navigation';
import { useSearchParams } from 'next/navigation';
import Grid from '@mui/material/Grid2';
import Link from 'next/link';
import withSuspense from 'ui/components/WithSuspense';

type Props = {
  homeUrl: string;
};

const RootHeader: React.FC<Props> = ({ homeUrl }) => {
  const t = useTranslations('RootHeader');
  const locale = useLocale();
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const handleEnglish = () => {
    router.replace(searchParams ? `${pathname}?${searchParams}` : pathname, { locale: 'en' });
  };

  const handleSpanish = () => {
    router.replace(searchParams ? `${pathname}?${searchParams}` : pathname, { locale: 'es' });
  };

  const isReferral = pathname && pathname.startsWith('/referral');

  return (
    <Grid
      container
      paddingLeft="0.5em"
      paddingTop="0.5em"
      height="68px"
      width="100%"
      flexWrap="nowrap"
    >
      <Grid display="flex" alignItems="center" justifyContent="start" flex={1}>
        <Link href={homeUrl} style={{ textDecoration: 'none' }}>
          <Image
            src="/assets/clarity-logo.svg"
            alt="Clarity Pediatrics logo"
            placeholder="blur"
            blurDataURL={'/assets/clarity-logo.svg'}
            width={128}
            height={64}
          />
        </Link>
      </Grid>
      <Grid
        display="flex"
        alignItems="center"
        size={{
          sm: 5,
          md: 4,
          lg: 3,
          xl: 3,
        }}
      >
        <Grid container justifyContent="end" padding={1}>
          <Grid flex={1} display="flex" alignItems="center" justifyContent="end">
            {locale === 'en' ? (
              <Button
                variant="contained"
                color="primary"
                onClick={handleSpanish}
                sx={{
                  padding: '0 0.5em',
                  fontSize: '1em',
                  visibility: isReferral ? 'hidden' : 'visible',
                }}
              >
                Español
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={handleEnglish}
                sx={{
                  padding: '0 0.5em',
                  fontSize: '1em',
                  visibility: isReferral ? 'hidden' : 'visible',
                }}
              >
                English
              </Button>
            )}
          </Grid>
          <Grid display="flex" alignItems="center">
            <Typography p={1}>{t('title')}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withSuspense(RootHeader);
